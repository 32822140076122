import {DEFAULT_ENVIRONMENT} from "./environment.default";

export const environment = {
  ...DEFAULT_ENVIRONMENT,
  production: true,
  host: 'https://core.aviaoperator.com',
  orderHost: 'https://office.aviaoperator.com',
  authHost: 'https://id360.aviaoperator.com',
  signalR: {
    ...DEFAULT_ENVIRONMENT.signalR,
    host: 'https://search.aviaoperator.com',
  },
  authConfig: {
    ...DEFAULT_ENVIRONMENT.authConfig,
    clientId: 'ro.frontend',
    redirectUri: 'https://aviaoperator.ge/auth-callback',
    authority: 'https://id360.aviaoperator.com/',
    postLogoutRedirectUri: 'https://aviaoperator.ge/signout-callback',
    silentRedirectUri: 'https://aviaoperator.ge/silent_renew.html',
  },
  IS_FULL_VERSION: false
};
